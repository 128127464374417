import { TFunction } from 'i18next';
import { Trans, useTranslation } from 'react-i18next';

import BadgeStatus from 'ui-kit/bagde/badge-status';
import AlertIcon from 'ui-kit/icons/alert/alert-icon';
import CartSteeringWheelIcon from 'ui-kit/icons/car-steering-wheel/car-steering-wheel-icon';
import DoctorIcon from 'ui-kit/icons/doctor/doctor-icon';
import CircleInfoV2 from 'ui-kit/icons/info-v2/circle-info-icon';
import LetterIcon from 'ui-kit/icons/letter/ticket-icon';
import ParentIcon from 'ui-kit/icons/parent/ticket-icon';
import TicketIcon from 'ui-kit/icons/ticket/ticket-icon';
import VideoIcon from 'ui-kit/icons/video/video-icon';
import Link from 'ui-kit/link/link';
import ToastBox from 'ui-kit/toast-box/toast-box';

import { handleEllipsis } from 'components/checkout/payment-information';

import { formatPhoneNumber } from 'pages/secure/membership/request-visit';

import { formatPrice } from 'schema/price.schema';

import { CreditCardPayload } from 'types/credit-card';
import { MembershipMembers } from 'types/membership';

import { getCreditCardEnding } from 'util/payments';

import useWindowDimensions from 'hooks/useWindowDimensions';

import './membership-request-visit-considerations-and-final-prices.style.scss';

interface FinalPricesProps {
    hasVisitAvailable: boolean;
    isOnDemandPlan: boolean;
    telehealthPrice: string | number | undefined;
    member: MembershipMembers;
    planName: string;
    selectedCardForVisit?: CreditCardPayload;
    defaultCreditCard?: CreditCardPayload;
    planOwner: MembershipMembers;
}

const defineToastBoxToUse = (
    defaultCreditCard: string,
    isOnDemandPlan: boolean,
    hasVisitAvailable: boolean,
    isCarePlus: boolean,
    t: TFunction<'translation', undefined>,
    selectedCreditCard?: string
) => {
    if (!isOnDemandPlan && hasVisitAvailable) {
        return (
            <ToastBox variant="success">
                <div className="request-visit-payment-information-toast-box">
                    <DoctorIcon />
                    <div>
                        <p id="first-line">{t('pages.requestTelemedicineVisit.contactInformation.notes.included')}</p>
                        <p>
                            {t('pages.requestTelemedicineVisit.contactInformation.notes.includedTwo', {
                                defaultCreditCard
                            })}
                        </p>
                    </div>
                </div>
            </ToastBox>
        );
    }

    const differentCcNote =
        selectedCreditCard !== defaultCreditCard ? (
            <ToastBox variant="info">
                <div className="request-visit-payment-information-toast-box">
                    <CircleInfoV2 />
                    <p>
                        <Trans
                            i18nKey="pages.requestTelemedicineVisit.contactInformation.notes.differentCreditCard"
                            values={{ defaultCreditCard }}
                        />
                    </p>
                </div>
            </ToastBox>
        ) : null;

    if (!isOnDemandPlan && !hasVisitAvailable) {
        return (
            <>
                <ToastBox variant="warning">
                    <div className="request-visit-payment-information-toast-box">
                        <AlertIcon variant="warning" />
                        <div>
                            <p id="first-line">
                                <Trans
                                    i18nKey={
                                        isCarePlus
                                            ? 'pages.requestTelemedicineVisit.contactInformation.notes.outOfVisitsCarePlusOne'
                                            : 'pages.requestTelemedicineVisit.contactInformation.notes.outOfVisitsCareOne'
                                    }
                                />
                            </p>
                            <p>
                                <Trans
                                    i18nKey={
                                        isCarePlus
                                            ? 'pages.requestTelemedicineVisit.contactInformation.notes.outOfVisitsCarePlusTwo'
                                            : 'pages.requestTelemedicineVisit.contactInformation.notes.outOfVisitsCareTwo'
                                    }
                                />
                            </p>
                        </div>
                    </div>
                </ToastBox>
                {differentCcNote}
            </>
        );
    }

    if (isOnDemandPlan) {
        return (
            <>
                <ToastBox variant="info">
                    <div className="request-visit-payment-information-toast-box">
                        <CircleInfoV2 />
                        <p>
                            <Trans i18nKey="pages.requestTelemedicineVisit.contactInformation.notes.upgrade" />
                        </p>
                        <Link to={'/secure/profile/membership/upgrade'} label={'Upgrade'} dataGALocation={''} />
                    </div>
                </ToastBox>
                {differentCcNote}
            </>
        );
    }

    return null;
};

const ConsiderationsAndFinalPrices = ({
    hasVisitAvailable,
    isOnDemandPlan,
    telehealthPrice,
    member,
    planName,
    selectedCardForVisit,
    defaultCreditCard,
    planOwner
}: FinalPricesProps) => {
    const { t } = useTranslation();
    const isMinor = member.accountType === 'Child' || member.accountType === 'Minor';
    const { email, phoneNumber } = isMinor ? planOwner : member;
    const isCarePlus = !isOnDemandPlan && planName.toLocaleLowerCase() === 'care +';
    const { width } = useWindowDimensions();

    const formattedDefaultCard =
        defaultCreditCard !== undefined
            ? `${handleEllipsis(defaultCreditCard?.cardType, width)} ${getCreditCardEnding(
                  defaultCreditCard?.cardNumber as string,
                  '····'
              )}`
            : '';
    const formattedSelectedCard =
        selectedCardForVisit !== undefined
            ? `${handleEllipsis(selectedCardForVisit?.cardType, width)} ${getCreditCardEnding(
                  selectedCardForVisit?.cardNumber as string,
                  '····'
              )}`
            : '';

    return (
        <div className="membership-request-visit-considerations">
            <div className="membership-request-visit-considerations__contact-details">
                <p>{t('pages.requestTelemedicineVisit.contactInformation.title')}</p>
                <div className="membership-request-visit-considerations__contact-details__personal-details">
                    <div className="membership-request-visit-considerations__contact-details__personal-details__phone">
                        <p>{t('pages.requestTelemedicineVisit.contactInformation.phoneNumber')}</p>
                        <p>{formatPhoneNumber(phoneNumber)}</p>
                    </div>
                    <div className="membership-request-visit-considerations__contact-details__personal-details__email">
                        <p>{t('pages.requestTelemedicineVisit.contactInformation.email')}</p>
                        <p>{email}</p>
                    </div>
                </div>
            </div>
            <div className="membership-request-visit-considerations__additional-considerations">
                <p>{t('pages.requestTelemedicineVisit.contactInformation.considerations.title')}</p>
                <div className="membership-request-visit-considerations__additional-considerations__list">
                    <div className="membership-request-visit-considerations__additional-considerations__list__item">
                        <CartSteeringWheelIcon />
                        <p>{t('pages.requestTelemedicineVisit.contactInformation.considerations.considerationOne')}</p>
                    </div>
                    <div className="membership-request-visit-considerations__additional-considerations__list__item">
                        <TicketIcon />
                        <p>{t('pages.requestTelemedicineVisit.contactInformation.considerations.considerationTwo')}</p>
                    </div>
                </div>
            </div>
            {isMinor && (
                <div className="membership-request-visit-considerations__additional-considerations">
                    <p>{t('pages.requestTelemedicineVisit.contactInformation.considerations.titleForMinors')}</p>
                    <div className="membership-request-visit-considerations__additional-considerations__list">
                        <div className="membership-request-visit-considerations__additional-considerations__list__item">
                            <LetterIcon />
                            <p>
                                {t(
                                    'pages.requestTelemedicineVisit.contactInformation.considerations.considerationThree'
                                )}
                            </p>
                        </div>
                        <div className="membership-request-visit-considerations__additional-considerations__list__item">
                            <ParentIcon />
                            <p>
                                {t(
                                    'pages.requestTelemedicineVisit.contactInformation.considerations.considerationFour'
                                )}
                            </p>
                        </div>
                        <div className="membership-request-visit-considerations__additional-considerations__list__item">
                            <VideoIcon />
                            <p>
                                {t(
                                    'pages.requestTelemedicineVisit.contactInformation.considerations.considerationFive'
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            )}
            <div className="membership-request-visit-considerations__liner"></div>
            <div className="membership-request-visit-considerations__visit-total">
                <p className="membership-request-visit-considerations__visit-total__title">
                    {t('pages.requestTelemedicineVisit.totalValue')}
                </p>
                <div className="membership-request-visit-considerations__visit-total__prices">
                    {!hasVisitAvailable || isOnDemandPlan ? (
                        <p className="membership-request-visit-considerations__visit-total__prices_value">
                            {formatPrice(telehealthPrice)}
                        </p>
                    ) : (
                        <>
                            <BadgeStatus variant="success" label={t('pages.requestTelemedicineVisit.includedInPlan')} />
                            <p className="membership-request-visit-considerations__visit-total__prices_value">$0.00</p>
                        </>
                    )}
                </div>
            </div>
            {defineToastBoxToUse(
                formattedDefaultCard,
                isOnDemandPlan,
                hasVisitAvailable,
                isCarePlus,
                t,
                formattedSelectedCard
            )}
        </div>
    );
};

export default ConsiderationsAndFinalPrices;
